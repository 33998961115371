import { IonButton, IonCheckbox, IonIcon, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Sectiontitle, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import NoData from '../../components/noData';
import Loading from '../../components/spinner';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { checkForDeliveryOption, createMomentFromTime, deepCopy, forwardTo, getDistanceUnit, isArray, isDefined, isObject, isTimeAvaibleInMenu, isWebConfig, parseAllergenData } from '../../lib/utils';
import { setDeliveryOption } from '../../store/actions';
import { loading, setCommonModal, setMyLocation, showToast } from '../../store/common/actions';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import { getIkentooMenu, getIkentooMenusForLocation, getRestaurants } from '../../store/restaurants/actions';
import { filterMenu } from '../../store/restaurants/sagas';
import { Geolocation } from '@capacitor/geolocation';
import 'moment-timezone';
import './index.css';
import 'moment-timezone';
import { isStoreOpened } from '../clickAndCollect';
import { warningOutline } from 'ionicons/icons';

const toWhichSegmentTimeBelongs = (time, segments = []) => {
	let timeIsInSegment = -1;
	segments.forEach((segment, index) => {
		const { start, end } = segment;
		const targetTime = createMomentFromTime(time);
		const segmentStart = createMomentFromTime(start);
		const segmentEnd = createMomentFromTime(end);
		if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
			timeIsInSegment = index;
		}
	});
	return timeIsInSegment;
};

const isTimeInSegment = (time, segments = []) => {
	return toWhichSegmentTimeBelongs(time, segments) !== -1;
};

const getMenusForSelectedTime = (menusForLocation = [], pick_up_at_counter_json_time_selector = []) => {
	if (isObject(menusForLocation) && !isArray(menusForLocation)) {
		menusForLocation = [menusForLocation];
	}
	const pickTime = moment().tz(getConfig().timezone).format('HH:mm');
	return menusForLocation.filter((menu) => {
		const ikentooMenuId = menu.ikentooMenuId;
		if (pick_up_at_counter_json_time_selector.length > 0 && pick_up_at_counter_json_time_selector[0].menuId && pickTime && ikentooMenuId) {
			const target_menus = pick_up_at_counter_json_time_selector.filter((i) => i.menuId.toString() === ikentooMenuId.toString());
			for (let i = 0; i < target_menus.length; i++) {
				if (
					target_menus[i] &&
					isTimeInSegment(
						pickTime,
						target_menus[i].availability.filter((i) => i.d === 'w' + moment().tz(getConfig().timezone).day()),
					)
				) {
					return true;
				}
			}
		} else {
			return false;
		}
		return false;
	});
};

class PickUpAtCounter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRestaurant: props.location.state && props.location.state.selectedRestaurant ? props.location.state.selectedRestaurant.restaurant_id : null,
			pickTime: null,
			error: '',
			modalOpen: false,
			isSelectedResButtonActive: true,
			continueButtonClicked: false,
			isLocationAllowed: false,
			renderAgain: false,
			showOnlyPickUpText: false,
			resturantIsDisabled: false,
		};
	}

	componentDidMount() {
		if (Basket.getDeliveryOption()?.id === 'gift-vouchers') {
			Basket.reset();
		}
		const { restaurants } = this.props;
		this.props.dispatch(loading(true));
		Basket.setOrderType('pick-up-at-counter');
		if (this.state.selectedRestaurant) {
			const selectedRestaurant = restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant);
			const orderType = getConfig().delivery.filter((el) => el.id === 'pick_up_at_counter');
			const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
			if (selectedRestaurant.can_pick_up_at_counter && selectedRestaurant.is_published && !orderTypeDisabled) {
				Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant));
				Basket.setServicePercentage(0);
				// Basket.setProcessingFee();
			} else {
				forwardTo('/history', { tab: 'order' });
				this.props.dispatch(showToast(this.props.__('This restaurant is currently not accepting orders'), 'warning'));
				Basket.reset();
			}
		}
		let option = (getConfig().delivery || []).find((d) => d.id === Basket.order_type);
		Basket.setDeliveryOption(option);
		checkForDeliveryOption(Basket.getDeliveryOption(), '/pick-up-at-counter').then((deliveryOption) => {
			if (deliveryOption) {
				this.haveOnePublishedResturant();
				this.props.dispatch(setDeliveryOption(deliveryOption));
			}
			if (Basket.getMenu()) {
				this.setState({ selectedIkentooMenu: Basket.getMenu() });
			}
			this.props.dispatch(loading(false));
		});
		this.position();
	}
	selectRestaurant = (selectedRestaurantId) => {
		const { restaurants, profile } = this.props;
		const restaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurantId);
		let additionalSettings = restaurant && restaurant.additional_delivery_settings ? restaurant.additional_delivery_settings : {};
		additionalSettings = additionalSettings['pick_up_at_counter'];
		const hasPickUpText = additionalSettings?.pickUpText && additionalSettings?.pickUpText !== '';

		if (this.props.location?.state?.saveOrderData && Basket.has_multi_basket) {
			const restaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurantId);
			this.setState({ pickTime: 'asap', selectedRestaurant: selectedRestaurantId, showOnlyPickUpText: hasPickUpText, showChooseMenu: !hasPickUpText }, () => {
				Basket.setRestaurant(restaurant);
				this.chooseMenusForLocation();
			});
		} else {
			this.setState({ selectedRestaurant: selectedRestaurantId, pickTime: 'asap', showOnlyPickUpText: hasPickUpText, showChooseMenu: !hasPickUpText }, async () => {
				Basket.reset(profile.cardToken);
				Basket.setRestaurant(restaurant);
				if (!this.props.location?.state?.saveOrderData) {
					if (getConfig().general.hasServiceCharge && getConfig().general.service_charge_modules?.includes('pick-up-at-counter')) {
						if (getConfig().flags.applyAutomaticServiceCharge) {
							if (Basket.getRestaurantServiceCharge()) {
								const service_charge_original = Basket.getRestaurantServiceCharge();
								Basket.setServicePercentage(parseFloat(service_charge_original[1] * 100));
							} else {
								const defaultServiceCharge =
									typeof getConfig().general.defaultServiceCharge == 'string' ? JSON.stringify(getConfig().general.defaultServiceCharge) : getConfig().general.defaultServiceCharge;
								Basket.setServicePercentage(parseFloat(defaultServiceCharge[1] * 100));
							}
						} else {
							Basket.setServicePercentage(0);
						}
					} else {
						Basket.setServicePercentage(0);
					}
				}
				Basket.setCollectionTime(null);
				Basket.setDeliveryOption(this.props.deliveryOption);
				Basket.setOrderType('pick-up-at-counter');
				this.props.dispatch(loading(true));
				let now = moment();
				if (restaurant && isDefined(restaurant.order_slot_lead_time)) {
					now.add(restaurant.order_slot_lead_time, 'minutes');
				}
				Basket.setASAP(true);
				Basket.setCollectionTime(now);
				await this.chooseMenusForLocation();
				this.props.dispatch(loading(false));
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextState.pickTime !== this.state.pickTime ||
			nextProps.restaurants !== this.props.restaurants ||
			nextState.selectedRestaurant !== this.state.selectedRestaurant ||
			nextProps.isChooseMenuModalOpen !== this.props.isChooseMenuModalOpen ||
			nextState.continueButtonClicked !== this.state.continueButtonClicked ||
			nextState.isLocationAllowed !== this.state.isLocationAllowed ||
			nextState.renderAgain !== this.state.renderAgain
		) {
			return true;
		} else {
			return false;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isLocationAllowed !== prevState.isLocationAllowed) {
			this.props.dispatch(getRestaurants());
		}
		checkForDeliveryOption(Basket.getDeliveryOption(), '/pick-up-at-counter').then(() => {
			if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
				if (this.props.ikentooMenusForLocation[0]) {
					this.setState({
						selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId,
					});
				}
			}
		});
	}

	selectStore = (store) => {
		this.setState({ selectedRestaurant: store.id, renderAgain: !this.state.renderAgain });
	};

	continueOnMenu = (selectedMenu = null) => {
		const { restaurants, dispatch } = this.props;
		const { selectedIkentooMenu, selectedRestaurant } = this.state;
		const menu = selectedMenu || selectedIkentooMenu;
		if (selectedRestaurant && menu) {
			const choosenRestaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurant);
			const businessLocationId = choosenRestaurant.business_location_id;
			dispatch(getIkentooMenu(menu, businessLocationId));
		} else {
			this.setState({ error: 'Please select location menu' });
		}
	};

	getMenuForReorder = (menus, reorderItems) => {
		let menuForReorder = [];
		for (let i = 0; i < menus.length; i++) {
			let foundItems = 0;
			const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
			let items = Basket.flattenMenuItems(deepCopy(categoryItems));
			menus[i].flattenMenuItems = items;
			if (items.length > 0) {
				for (let j = 0; j < reorderItems.length; j++) {
					let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
					if (foundItem) {
						foundItems = foundItems + 1;
					}
				}
			}
			menus[i].foundItems = foundItems;
			menuForReorder.push(menus[i]);
		}
		menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
		if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
			return filterMenu(menuForReorder[0]);
		} else {
			return false;
		}
	};

	chooseMenusForLocation = async () => {
		const { restaurants, dispatch } = this.props;
		const { selectedRestaurant, pickTime } = this.state;
		this.setState({ continueButtonClicked: true }, async () => {
			if (selectedRestaurant && pickTime) {
				const choosenRestaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurant);
				const businessLocationId = choosenRestaurant.business_location_id;
				let currentDT = moment().tz(getConfig().timezone);
				let h = parseInt(pickTime.split(':')[0]);
				let m = parseInt(pickTime.split(':')[1]);
				const formattedDT = moment(currentDT).hours(h).minutes(m);
				Basket.setCutoffTime(currentDT.unix());
				Basket.setCollectionTime(formattedDT);
				if (this.props.location.state && this.props.location.state.selectedRestaurant) {
					let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
					ikentooMenusForLocation = getMenusForSelectedTime(ikentooMenusForLocation, choosenRestaurant.pick_up_at_counter_json_time_selector);
					if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
						let ikentooMenus = [];
						try {
							for (let i = 0; i < ikentooMenusForLocation.length; i++) {
								ikentooMenus.push(await api.getIkenooMenu(ikentooMenusForLocation[i].ikentooMenuId, businessLocationId));
							}
						} catch (error) {
							this.setState({ error: 'Get restaurant menu error.' });
							this.setState({ continueButtonClicked: false });
						}
						const reorderItems = this.props.location.state.selectedRestaurant.items;
						const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
						if (menu) {
							dispatch({
								type: SET_RESTAURANT_PROP,
								key: 'ikentooMenu',
								value: menu,
							});
							if (menu.flattenMenuItems.length > 0) {
								let validationStatus = {
									notBasketEmpty: false,
									validationError: false,
								};
								reorderItems.map((newBasketItem) => {
									let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku);
									if (foundItem && Basket.isProductEnabled(foundItem)) {
										if (newBasketItem.selectedChoices[0]) {
											newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter((el) =>
												getConfig().flags.showSnoozedProducts ? Basket.isProductJustEnabled(el) : Basket.isProductEnabled(el),
											);
										}
										newBasketItem.item = foundItem;
										Basket.addToBasket(newBasketItem);
										let item = newBasketItem.item;
										let allergens = this.props.allergens;
										let profile = this.props.profile;
										let newArr = parseAllergenData(profile, item, allergens);
										let allergensCodes = newBasketItem.item?.itemRichData?.allergenCodes.length > 0 ? newBasketItem.item.itemRichData.allergenCodes : [];
										if (allergensCodes.length > 0) {
											let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
											Basket.setAllergen(allergensData);
										}
										validationStatus.notBasketEmpty = true;
									} else {
										validationStatus.validationError = true;
									}
								});
								if (validationStatus.notBasketEmpty) {
									if (validationStatus.validationError) {
										dispatch(showToast('Some items were not added to your basket as they are currently unavailable', 'warning'));
										this.setState({ continueButtonClicked: false });
									}
									forwardTo('/order-summary', {
										skipBackToThePreviousPage: false,
									});
								} else {
									dispatch(showToast('Some items were not added to your basket as they are currently unavailable', 'warning'));
									this.setState({ continueButtonClicked: false });
								}
							}
						} else {
							dispatch(showToast('Menu not found', 'warning'));
							this.setState({ continueButtonClicked: false });
						}
					} else {
						dispatch(showToast('Menu not found', 'warning'));
						this.setState({ continueButtonClicked: false });
					}
				} else {
					dispatch(
						getIkentooMenusForLocation(
							businessLocationId,
							{
								pickTime,
								json_time_selector: choosenRestaurant ? choosenRestaurant.pick_up_at_counter_json_time_selector : [],
							},
							false,
						),
					);
					this.setState({ continueButtonClicked: false });
				}
			} else if (!selectedRestaurant) {
				this.setState({ error: 'Please select location' });
			} else {
				this.setState({ error: 'Please select pickup time' });
			}
		});
	};

	changeIkentooMenus = (event) =>
		this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
			Basket.setMenu(event.detail.value);
		});

	position = async () => {
		const myLocation = {
			latitude: null,
			longitude: null
		};
		try {
			const coordinates = await Geolocation.getCurrentPosition({
				enableHighAccuracy: false,
			});
			myLocation.latitude = coordinates.coords.latitude;
			myLocation.longitude = coordinates.coords.longitude;
			this.setState({ isLocationAllowed: true });
		} catch (error) {
			console.log('Error getting location: ' + error.message);
			this.setState({ isLocationAllowed: false });
		}
		this.props.dispatch(setMyLocation(myLocation));
	};

	formatDataForSelect = (stores) => {
		let formatedStores = [];
		stores.forEach((store) => {
			const currentDT = moment().tz(getConfig().timezone);
			let minDT = currentDT;
			if (store && isDefined(store.order_slot_lead_time)) {
				minDT.add(store.order_slot_lead_time, 'minutes');
			}
			const storeOpened = isStoreOpened(store);
			const slotsAvailable = isTimeAvaibleInMenu(store.pick_up_at_counter_json_time_selector, currentDT);
			store.opened = slotsAvailable;

			if (storeOpened && slotsAvailable) {
				store.openLabel = 'OPEN';
			} else if (storeOpened && !slotsAvailable) {
				store.openLabel = 'UNAVAILABLE';
			} else if (!storeOpened && slotsAvailable) {
				store.openLabel = 'PREORDER';
			} else if (!storeOpened && !slotsAvailable) {
				store.openLabel = 'CLOSED';
			}
			if (store.is_published && store.can_pick_up_at_counter) {
				formatedStores.push(store);
			}
		});
		if (this.state.isLocationAllowed) {
			formatedStores.sort(function (a, b) {
				return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
			});
		} else {
			formatedStores.sort(function (a, b) {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
		}
		return formatedStores;
	};

	haveOnePublishedResturant = () => {
		const { __ } = this.props;
		const restaurantArr = this.props.restaurants.filter((restaurant) => restaurant.is_published === true && restaurant.can_collection_order === true);
		if (restaurantArr.length === 1 && this.state.selectedRestaurant === null && restaurantArr[0].opened) {
			const restaurant = restaurantArr[0];
			const additional_delivery_settings = restaurant.additional_delivery_settings || {};
			const additionalSettings = additional_delivery_settings[this.props.deliveryOption?.id] || {};
			if (!isStoreOpened(restaurant)) {
				this.setState({ resturantIsDisabled: true });
			} else {
				this.selectRestaurant(restaurant.id);
				this.setState({ isSelectedResButtonActive: false });
			}
		}
	};

	getKitchenStatusColor(status) {
		let color = '';
		switch (status) {
			case 0:
				color = 'success';
				break;
			case 1:
				color = 'warning';
				break;
			case 2:
				color = 'danger';
		}
		return color;
	}

	render() {
		const { __, restaurants, ikentooMenusForLocation, isChooseMenuModalOpen, deliveryOption } = this.props;
		const { error, selectedRestaurant, pickTime, selectedIkentooMenu, showOnlyPickUpText, resturantIsDisabled } = this.state;
		const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
		const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
		const stores = restaurants || [];
		const menus = getMenusForSelectedTime(ikentooMenusForLocation, store && store.pick_up_at_counter_json_time_selector ? store.pick_up_at_counter_json_time_selector : []);
		let formatedStores = this.formatDataForSelect(stores);
		let additionalSettings = store && store.additional_delivery_settings ? store.additional_delivery_settings : {};
		additionalSettings = additionalSettings['pick_up_at_counter'];
		const pickUpText = additionalSettings?.pickUpText;
		if (Basket.has_multi_basket && getConfig().flags.geolocationDisabled) {
			const stores = deepCopy(formatedStores);
			let openedRestaurants = stores.filter((store) => store.opened);
			let closedRestaurants = stores.filter((store) => !store.opened);
			openedRestaurants.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
			openedRestaurants.sort((a, b) => a.sort_order - b.sort_order);
			closedRestaurants.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
			closedRestaurants.sort((a, b) => a.sort_order - b.sort_order);
			formatedStores = [...openedRestaurants, ...closedRestaurants];
		}
		return (
			<Loading transparent>
				<Layout showLoginIcon={getConfig().flags.hasGuestCheckout && Basket.itemsCount() == 0} showHamburger title={__('Pick up at Counter')} headerWithTitle={true} color="transparent" scrollY={false}>
					<div className="flex-row-wrapper absolute-content">
						<div>
							<div className="click-and-collect-title">
								<Title className="web-only">{__('Pick up at Counter')}</Title>
								{showOnlyPickUpText ? null : <NormalText>{__('Select a restaurant to collect your order')}</NormalText>}
							</div>
							{!showOnlyPickUpText || ikentooMenusForLocation.length === 0 ? (
								<>
									<div className="click-and-collect-locations">
										<IonList className={`box-wrapper ${getConfig().general.tiledRestaurantView ? 'tiled-box-wrapper' : ''}`}>
											{formatedStores.map((store, i) => (
												<>
													{getConfig().general.tiledRestaurantView ? (
														<IonItem
															disabled={!store.can_pick_up_at_counter || resturantIsDisabled || !store.opened}
															className="pointer restaurant-wrapper"
															onClick={() => this.selectRestaurant(store.id)}
															lines="none"
															key={i}
														>
															<div style={{ width: '100%' }}>
																<img src={store.image} />
																<IonLabel>
																	<StrongText>{__(store.name)}</StrongText>
																	{store.counter_kitchen_status && (
																		<>
																			{(store.counter_kitchen_status?.status == 1 || store.counter_kitchen_status?.status == 2) && (
																				<SmallText
																					className={`kitchen-status-wrapper flex-wrapper flex-align-center block ${this.getKitchenStatusColor(
																						store.counter_kitchen_status.status,
																					)}-color`}
																				>
																					<IonIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} icon={warningOutline}></IonIcon>
																					{store.counter_kitchen_status.text}
																				</SmallText>
																			)}
																		</>
																	)}
																	<div className="restaurant-data" dangerouslySetInnerHTML={{ __html: store.info_html }}></div>
																</IonLabel>
															</div>
														</IonItem>
													) : (
														<IonItem
															disabled={!store.can_pick_up_at_counter || resturantIsDisabled || !store.opened}
															onClick={() => this.selectStore(store)}
															lines="none"
															key={i}
														>
															<IonLabel className="break-space">
																<StrongText>{__(store.name)}</StrongText>
																<SmallText className="block">{__(store.address)}</SmallText>
																{store.distance && !Basket.has_multi_basket && (
																	<SmallText className=" block bold-description click-collect-small-description">
																		{`${parseFloat(store.distance.toFixed(2)) == 1 ? store.distance.toFixed(0) : store.distance.toFixed(2)} ${__(getDistanceUnit(parseFloat(store.distance.toFixed(2)) == 1))}`}
																	</SmallText>
																)}
																{store.counter_kitchen_status && (
																	<SmallText className={`kitchen-status-wrapper ${this.getKitchenStatusColor(store.counter_kitchen_status.status)}-color block`}>
																		{store.counter_kitchen_status.text}
																	</SmallText>
																)}
																<SmallText className="restaurant-opening-time">{__(store.openLabel)}</SmallText>
															</IonLabel>
															<IonCheckbox checked={selectedRestaurant && store.id === selectedRestaurant} slot="start" color="primary" />
														</IonItem>
													)}
												</>
											))}
										</IonList>
									</div>
									{getConfig().frenchDisclaimer?.disclaimerImage && (
										<div className="disclaimer-wrapper">
											<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
										</div>
									)}
									{!getConfig().general.tiledRestaurantView && (
										<div className="click-and-collect-button-wrapper">
											<IonButton
												disabled={!selectedRestaurant || formatedStores.length === 0}
												expand="block"
												color="primary"
												onClick={() => this.selectRestaurant(selectedRestaurant)}
											>
												{__('Continue')}
											</IonButton>
										</div>
									)}
								</>
							) : (
								<>
									<div className="click-and-collect-title">
										<Spacer size={1} />

										<div dangerouslySetInnerHTML={{ __html: pickUpText }} />
									</div>
									<Spacer size={1} />
									<div className="click-and-collect-button-wrapper">
										<IonButton
											disabled={!pickTime}
											expand="block"
											color="primary"
											className={this.state.continueButtonClicked ? 'unclicked' : ''}
											onClick={() => (menus && menus.length === 1 ? this.continueOnMenu(menus[0].ikentooMenuId) : this.chooseMenusForLocation())}
										>
											{__('Continue')}
										</IonButton>
									</div>
								</>
							)}
						</div>
					</div>
					{menus.length && menus > 1 ? (
						<>
							<div
								className="click-collect-pickers-backdrop"
								style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
								onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
							></div>

							<div className={`click-collect-dialog ${animationMenuClass}`}>
								<div className="click-collect-dialog-layout sc-ion-modal-md">
									<div className="click-collect-dialog-header">
										<Title>{__('Choose menu')}</Title>
									</div>
									<div className="click-collect-dialog-closer" onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}>
										<ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
									</div>
									<div className="click-collect-dialog-content">
										<IonList lines="none" className="box-wrapper">
											<IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
												{!menus.length ? (
													<NoData />
												) : (
													menus.map((menu) => {
														const { ikentooMenuId, menuName } = menu;
														return (
															<IonItem key={ikentooMenuId} lines="none">
																<div tabIndex="-1"></div>
																<NormalText className="ion-text-wrap">
																	<Sectiontitle>{menuName}</Sectiontitle>
																</NormalText>
																<IonRadio color="primary" slot="start" value={ikentooMenuId} />
															</IonItem>
														);
													})
												)}
											</IonRadioGroup>
										</IonList>
									</div>
									<div className="click-collect-dialog-action">
										{error ? (
											<IonItem>
												<div tabIndex="-1"></div>
												<FieldError className="field-error" value={__(error)} />
											</IonItem>
										) : null}
										<IonButton
											disabled={pickTime && menus.length > 0 ? false : true}
											expand="block"
											color="primary"
											className="customized-button"
											onClick={() => this.continueOnMenu()}
										>
											{__('Continue')}
										</IonButton>
									</div>
								</div>
							</div>
						</>
					) : null}
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { auth, isChooseMenuModalOpen, orderProductionMins } = state.common;
	const { restaurants, ikentooMenu, ikentooMenusForLocation } = state.restaurants;
	const { deliveryOption } = state.orders;
	return {
		auth,
		isChooseMenuModalOpen: isChooseMenuModalOpen,
		restaurants: restaurants || [],
		ikentooMenu: ikentooMenu || {},
		ikentooMenusForLocation: ikentooMenusForLocation || [],
		profile: state.profile.profile,
		deliveryOption,
		orderProductionMins: orderProductionMins,
	};
};

export default connect(stateToProps)(withTranslation(PickUpAtCounter));
